"use client";

import { IInfoPopupProps } from "@/interfaces/InfoPopup/InfoPopup";
import React, { FC, useEffect, useState } from "react";
import CustomIconifyIcon from "../CustomIconifyIcon/CustomIconifyIcon";
import CloseIcon from "../Icons/CloseIcon";
import { usePathname } from "next/navigation";
import Typography from "../Typography/Typography";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/infoDialog";

const InfoPopup: FC<IInfoPopupProps> = (data) => {
  const { title, items } = data;
  const [expanded, setExpanded] = useState(true);
  const [show, setShow] = useState(true);
  const [path, setPath] = useState("");
  const pathName = usePathname();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  useEffect(() => {
    if (pathName !== "") {
      setPath(pathName);
    }
  }, [pathName]);

  useEffect(() => {
    if (path && show) {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  const closeModal = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      {isClient && (
        <div>
          <Dialog open={show} modal={false}>
            <DialogContent
              className={`cxs:${show ? "" : "hidden"} fixed w-30.875 cxs:top-auto cxs:bottom-0 cxs:left-0 z-[60] p-6 cxs:p-6`}
            >
              <DialogHeader>
                <DialogTitle className="flex justify-between items-center">
                  <Typography
                    variant="box-title"
                    className="text-white !text-0.8125 font-extrabold !uppercase !leading-0.975 tracking-widest"
                  >
                    {title}
                  </Typography>
                  <span
                    className="flex items-center justify-center w-1.8125 h-1.8125 cursor-pointer rounded-full p-2 bg-colorSecondary"
                    onClick={closeModal}
                  >
                    {expanded ? (
                      <CloseIcon color="#ffffff" />
                    ) : (
                      <CustomIconifyIcon
                        name={"formkit:down"}
                        className={"rotate-180 w-1.063 h-10 ease-out duration-300 text-white"}
                      />
                    )}
                  </span>
                </DialogTitle>
                <DialogDescription className={`${expanded ? "flex flex-col" : "hidden"} !mt-6`}>
                  {items?.map((item, index) => (
                    <div
                      key={`info-${index}`}
                      className="text-white w-fit flex justify-center items-center gap-6 py-1.5"
                    >
                      <CustomIconifyIcon name={item.iconifyIcon} className="w-5 h-5" />
                      <Typography
                        variant="paragraph-light"
                        className="text-white customxs:!text-base !font-medium normal-case tracking-normal !leading-6"
                      >
                        {item.title}
                      </Typography>
                    </div>
                  ))}
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
            {/* ************Mobile screen************ */}
            <DialogContent
              className={`${expanded ? "" : "hidden"} fixed w-[90%] top-[20%] left-1/2 transform -translate-x-1/2 cxs:hidden`}
            >
              <DialogHeader>
                <DialogTitle className="flex justify-between w-full">
                  <Typography
                    variant="box-title"
                    className="text-white !text-0.8125 font-medium text-start !tracking-widest"
                  >
                    {title}
                  </Typography>
                  <span
                    className="flex items-center justify-center w-1.8125 h-1.8125 cursor-pointer rounded-full p-2 bg-colorSecondary"
                    onClick={closeModal}
                  >
                    <CloseIcon color="#ffffff" width="9" height="10" />
                  </span>
                </DialogTitle>
                <DialogDescription>
                  <div className="border border-colorSecondary my-1"></div>
                  <div>
                    {items?.map((item, index) => (
                      <div
                        key={`info-${index}`}
                        className="text-white w-fit flex justify-center items-center gap-3 py-1"
                      >
                        <CustomIconifyIcon name={item.iconifyIcon} className="w-4 h-4" />
                        <Typography
                          variant="button-label"
                          className="!text-0.679 text-white normal-case tracking-normal font-semibold text-start whitespace-nowrap"
                        >
                          {item.title}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default InfoPopup;
