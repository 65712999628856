"use client";

import { useState, useEffect } from "react";
import { useRouter, usePathname } from "next/navigation";
import { useCurrentLocale } from "next-i18n-router/client";

import GlobeIcon from "@/components/Icons/GlobeIcon";
import CloseIcon from "@/components/Icons/CloseIcon";

import { LocalesMapping } from "@/constants/localesMapping";
import i18nConfig from "../../../i18nConfig";
import { setLocaleCookie } from "@/lib/utils";
import { Config } from "next-i18n-router/dist/types";

const LanguageSelector = (props: any) => {
  const { locales } = props;
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const currentPathname = usePathname();
  const currentLocale = useCurrentLocale(i18nConfig as Config);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("overflow-hidden");
  };

  const closeModal = () => {
    setIsClosing(true);
    document.body.classList.remove("overflow-hidden");
    setTimeout(() => {
      setIsModalOpen(false);
      setIsClosing(false);
    }, 300);
  };

  // Set selected lang by setting the NEXT_LOCALE cookie
  const changeLanguage = (newLocale: string) => {
    if (currentLocale !== newLocale) {
      setLocaleCookie(newLocale);
      closeModal();

      if (currentLocale !== newLocale)
        if (currentLocale === i18nConfig.defaultLocale && !i18nConfig.prefixDefault) {
          router.push("/" + newLocale + currentPathname);
        } else {
          router.push(currentPathname.replace(`/${currentLocale}`, `/${newLocale}`));
        }

      router.refresh();
    } else {
      closeModal();
    }
  };

  // Add an event listener to check if user clicked on modal backdrop to close the modal
  useEffect(() => {
    // Handles the click event on the window and closes the modal if the target is the language-selector-modal.
    const handleWindowClick = (event: MouseEvent) => {
      // if ((event.target as Element).classList.contains(styles["language-selector-modal"])) {
      if ((event.target as Element).classList.contains("language-selector")) {
        closeModal();
      }
    };
    // Add the event listener to the window object
    window.addEventListener("click", handleWindowClick);
    // Remove the event listener when component unmounted
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const LanguageListItem = ({ localeItem }: { localeItem: string }) => {
    if (!LocalesMapping[localeItem]) return;
    const { language, flag: FlagComponent } = LocalesMapping[localeItem];
    return (
      <li
        className="flex items-center justify-center gap-2 py-4 border border-[#ededed] rounded-lg my-2 cursor-pointer"
        onClick={() => changeLanguage(localeItem)}
        id={`${localeItem}-language-selector-button`}
      >
        <FlagComponent />
        <span className="uppercase font-roboto text-base font-semibold text-colorTertiary tracking-widest leading-1.375">
          {localeItem}
        </span>
        <span className="w-0 h-3 border border-[#ededed]"></span>
        <span className="font-roboto text-base font-light text-colorTertiary  leading-1.375">
          {language as string}
        </span>
      </li>
    );
  };

  return (
    <>
      <button
        className="flex flex-col cursor-pointer text-0.8125 font-semibold font-roboto gap-2 px-3 py-2 rounded-md items-center bg-white bg-opacity-10 border border-gray-600 border-opacity-30 text-white"
        onClick={openModal}
        id="navbar-language-selector"
      >
        <GlobeIcon width="18" height="19" color="white" />
        <span className="uppercase font-roboto tracking-widest">{currentLocale}</span>
      </button>
      <div
        className={`language-selector fixed z-1021 left-0 top-0 w-full h-full overflow-hidden bg-black bg-opacity-40 ${
          isModalOpen ? "block animate-fade-in" : "hidden"
        } `}
        onAnimationEnd={() => {
          if (!isModalOpen) {
            closeModal();
          }
        }}
      >
        <div
          className={`m-auto my-[50%] cxs:fixed cxs:right-16 cxs:top-20 cxs:translate-y-0 cxs:mx-[1%] cxs:my-[3%]  bg-white px-4 py-4 border border-gray-300 rounded-lg w-18.75 animate-slide-in ${isClosing ? "animate-slide-out" : ""} `}
        >
          <div className="flex justify-between items-center mb-4">
            <GlobeIcon
              className="w-full ml-5"
              width="18"
              color="#333333"
              height="19"
              style={{ marginRight: "8px" }}
            />
            <span onClick={closeModal}>
              <CloseIcon color="#666666" style={{ cursor: "pointer" }} />
            </span>
          </div>
          <h6 className="text-xl font-semibold text-colorTertiary mb-6 text-center font-roboto">
            Select your language
          </h6>
          <ul className="p-0 m-0">
            {locales?.length > 1 &&
              locales.map((localeItem: string, index: number) => {
                return <LanguageListItem key={index} localeItem={localeItem} />;
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LanguageSelector;
